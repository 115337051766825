import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMainVault } from "../../redux/vaultSlice";
import { replaceMenu } from "../../redux/drawerSlice";
import { HomeTemplate } from "../sidebar/HomeTemplate";
import { selectCompany } from "../../redux/companySlice";
import { Box, Container } from "@mui/material";
import { Title2, Title1, Divider } from "@fluentui/react-components";
import { Vault } from "./Vault";
import {
    FolderRegular,
    PeopleRegular,
    DocumentPdfRegular,
    OpenRegular
} from "@fluentui/react-icons";
import { FolderForm } from "./FolderForm";
import folderService from "../../services/folder_service";
import { DocumentForm } from "./DocumentForm";
import documentService from '../../services/document_service'
import aiVectorizeDoc from "../../services/ai_vetorize_doc"

export const VaultContainer = ({ createDocument = null , visible = true, initFolder = null, title = 'Vault', fileTypes = [".pdf"], allowNavigationHeader = true }) => {
    const dispatch = useDispatch();
    const { vault, status, error } = useSelector((state) => state.vault);
    const companyId = useSelector((state) => state.company.company.id);



    const [currentPage, setCurrentPage] = useState(0);

    const [documents, setDocuments] = useState([]);

    const [newFolder, setNewFolder] = useState(false)

    const [folderId, setFolderId] = useState(null)

    const handlePageChange = (page) => {
        setCurrentPage(page);
        // Aquí puedes manejar el cambio de página, por ejemplo, fetch data, etc.
    };

    const item = {
        id: "",
        file: { label: "Purchase order", icon: <DocumentPdfRegular /> },
        hash: null,
        author: { label: "Jane Doe", status: "offline" },
        lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
        lastUpdate: {
            label: "You shared this in a Teams chat",
            icon: <OpenRegular />,
        },
    };

    const handleLoadMenu = () => {
        dispatch(replaceMenu({ component: 'HomeTemplate', props: { selected: 'vault' } }));
    };

    const handleItemsAdapter = (folders) => {

        const currentFolderId = vault.data.id

        const tmp = folders
            .filter((data) => data.id !== currentFolderId)
            .filter((data) => data.name !== 'root')
            .filter((data) => data.name !== 'sheets')

            .map((data) => {
                return {
                    ...item,
                    id: data.id,
                    hash: data.ipfsHash,
                    file: { label: data.name, icon: data.fileType === 'folder' ? <FolderRegular /> : <DocumentPdfRegular /> },
                    author: { label: data.createdBy, status: "offline" }
                };
            });



        setDocuments(tmp);
    };

    const handleLoadDocuments = () => {
        if (companyId) {
            dispatch(fetchMainVault({ companyId, page: currentPage, folderId }));
        }
    };

    const handleOpenFolder = (item) => {

        setFolderId(item.id)
        setCurrentPage(0)



    }

    const newFolderForm = () => {

        if (vault.data.name !== 'root') {

            window.document.getElementById('file').click();
        } else {

            setNewFolder(!newFolder)
        }




    }

    const handleCreateFolder = async (folder) => {

        const _folderId = folderId != null ? folderId : localStorage.getItem('folderId');

        const response = await folderService.createFolder({ 'parentFolderId': _folderId, 'companyId': companyId, 'name': folder })

        handleLoadDocuments()

        setNewFolder(false)




    }

    const handleCreateDocument = async (e) => {

        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('folderId', folderId)
        formData.append('companyId', companyId);

        const doc = await documentService.createDocument(formData);
        const vectorize = await aiVectorizeDoc.vectorize_pdf(doc);
        console.log(vectorize);


        handleLoadDocuments()




    }

    useEffect(() => {

        initFolder && handleOpenFolder(initFolder)

    }, [initFolder])

    useEffect(() => {
        handleLoadMenu();
        dispatch(selectCompany());
    }, [dispatch]);

    useEffect(() => {
       visible && handleLoadDocuments();
    }, [companyId, currentPage, dispatch, folderId , visible]);

    useEffect(() => {
        if (vault && status === 'succeeded') {
            handleItemsAdapter(vault.childrens.content);
        }
    }, [vault, status]);

    return (
        <Container>
            <Box sx={{ paddingTop: '5.7rem', paddingBottom: '3vh' }}>
                <Title1>{title}</Title1>
            </Box>
            {status === 'succeeded' && vault && !newFolder && (
                <React.Fragment>
                        <Vault
                            allowNavigationHeader={allowNavigationHeader}
                            currentFolder={vault.data}
                            openFolder={handleOpenFolder}
                            createFolder={newFolderForm}
                            folders={documents}
                            currentPage={vault.childrens.pageable.pageNumber}
                            totalItems={vault.childrens.totalElements}
                            itemsPerPage={vault.childrens.pageable.pageSize}
                            handlePageChange={handlePageChange}
                        />
                </React.Fragment>
            )}
            {newFolder && <FolderForm close={newFolderForm} create={handleCreateFolder} />}
            {<DocumentForm acceptedFileTypes={fileTypes} create={createDocument != null ?createDocument : handleCreateDocument} />}
            <br />
        </Container>
    );
};