import React, { useEffect, useState } from "react"
import loginService from "../../services/login_service"
import { Login } from "./Login"
import { getNavBarParam } from "../../utils/BrowserUtils"
import { jwtDecode } from "jwt-decode"
import { clearLocalStorage } from "./TokenExpirationHandler"
import { useNavigate } from 'react-router-dom'
import { Container } from "@mui/material"
import { Text } from "@fluentui/react-components"
export const LoginContainer = () => {

    const navigate = useNavigate();

    const [ssoData, setSsoData] = useState({})
    const [code, setCode] = useState(null);
    const [error, setError] = useState(false)


    const host = window.location.host
    const protocol = window.location.protocol
    const redirecturi = `${protocol}//${host}/login`

    const handleError = () => {
        setError(true)

    }

    const handleMicrosotfLogin = async () => {

        const data = await loginService.generateMicrsoftSSOLink(redirecturi)

        localStorage.setItem('code_verifier', data.respuesta.code_verifer)

        window.location.href = data.respuesta.endpoint


    }

    const authToLocalStorage = (authData) => {

        const stringFormat = JSON.stringify(authData);
        const accessToken = authData.access_token;
        const refleshToken = authData.refresh_token;
        const decodeToken = jwtDecode(accessToken)

        const strinfyDecodedToken = JSON.stringify(decodeToken);
        localStorage.setItem('token', accessToken);
        localStorage.setItem('decoded_token', strinfyDecodedToken);
        localStorage.setItem('refresh_token', refleshToken);


    }

    const handleTokenByCode = (code, redirect_uri) => {
        const code_verifier = localStorage.getItem('code_verifier')

        setTimeout(() => {
            loginService.loginWitCode(code, code_verifier, redirect_uri)
                .then((data) => {
                    authToLocalStorage(data.respuesta)
                    handleRedirectToHome()


                })
                .catch((err) => {
                    handleError()
                    setTimeout(() => {
                        window.location.href = redirecturi
                    }, 500);
                })


        }, 3);




    }

    const handleLogin = async (user, password) => {
        try {
            const response = await loginService.login(user, password);
            authToLocalStorage(response)
            handleRedirectToHome()
        } catch (error) {

            handleError()

            console.log(error)

        }



    }


    const handleMicrosofGenerateEndpoint = async () => {
        await clearLocalStorage()
        const data = await loginService.generateMicrsoftSSOLink(redirecturi)
        setSsoData(data)


    }

    const handleRedirectToRoot = () => {
        window.location.href = '/'
    }

    const handleRedirectToHome = () => {

        navigate('/company')

    }




    useEffect(() => {

        const fechData = async () => {
            const code = getNavBarParam('code')
            setCode(code)

            if (code) {

                handleTokenByCode(code, redirecturi)


            } else {

                await clearLocalStorage()
                handleMicrosofGenerateEndpoint();

            }


        }
        fechData();


    }, [])


    return (
        <React.Fragment>
            {
                code ?
                    <></> :
                    <Container>
                        <Login handleMicrosoft={handleMicrosotfLogin} redirectToRoot={handleRedirectToRoot} handleLogin={handleLogin} error={error} />
                    </Container>
            }

        </React.Fragment>
    )

}