import React from 'react';
import {
    makeStyles,
    shorthands,
    tokens,
    Text,
    Card,
    mergeClasses,
    CardFooter,
    Link,
    Skeleton,
    SkeletonItem,
    Caption1,
    CardHeader,
} from "@fluentui/react-components";
import { Box } from '@mui/material';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...shorthands.gap('8px'),
    },
    messageList: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('8px'),
        width: '100%',
    },
    messageItem: {

        width: '100%',
    },
    messageSent: {
        justifyContent: 'flex-end',
    },
    messageReceived: {
        justifyContent: 'flex-start',
    },
    messageCard: {
        marginRight: '0.5rem',
        padding: '1rem'

    },
    messageSentCard: {
        backgroundColor: tokens.colorBrandBackgroundHover,
    },
    messageReceivedCard: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    messageText: {
        whiteSpace: 'pre-wrap',

    },
    messageSentText: {
        color: tokens.colorBrandBackgroundInverted,
    },
    messageReceivedText: {
        color: tokens.colorNeutralBackgroundInverted,
    },
});

export const HistoryChats = ({ messages = [], selectedDocument = null }) => {
    const styles = useStyles();
    const textEmpyFlag = "-1"

    return (
        <div className={styles.container}>
            <div className={styles.messageList}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={mergeClasses(styles.messageItem, message.isSender ? styles.messageSent : styles.messageReceived)}
                    >
                        <Card
                            className={mergeClasses(styles.messageCard, message.isSender ? styles.messageSentCard : styles.messageReceivedCard)}
                        >

                        { !message.isSender && message.image &&   <img height={"90%"} width={"70%"} src={`data:image/png;base64,${message.image}`  } />    }


                            <Text
                                className={mergeClasses(styles.messageText, message.isSender ? styles.messageSentText : styles.messageReceivedText)}
                            >
                                {message.secondary === textEmpyFlag ? 
                                   (
                                    <Skeleton>
                                        <SkeletonItem />
                                    </Skeleton>
                                   )
                                    :
                                  message.secondary 
                                }
                               
                            </Text>

                            {!message.isSender &&
                                <CardFooter>

                                  {  message.subdata &&  <Text className={styles.messageText} italic size={200}> { `Procesando: \n ${message.subdata}`} </Text>}

                                    <Box display={'flex'} gap={2} flexDirection={'row'} >

                                  { message.metadata && 
                                    message.metadata.map((meta,index) => {
                                        const data = JSON.parse(meta)
                                        return <Box key={index} > <Link onClick={() => {selectedDocument && selectedDocument(data,message.metadata)}} >{` [${data.title} - page : ${data.page} ]`}</Link> </Box>
                                    })
                                   }
                                   </Box>

                                </CardFooter>
                            }

                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};