
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createDarkTheme, createLightTheme } from '@fluentui/react-components';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

import App from './App';
import { Container } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store';
import "./index.css"
 

const ai = { 
   10: "#020305",
  20: "#111723",
  30: "#16263D",
  40: "#193253",
  50: "#1B3F6A",
  60: "#1B4C82",
  70: "#18599B",
  80: "#1267B4",
  90: "#3174C2",
  100: "#4F82C8",
  110: "#6790CF",
  120: "#7D9ED5",
  130: "#92ACDC",
  140: "#A6BAE2",
  150: "#BAC9E9",
  160: "#CDD8EF"
};


  const lightTheme = {
    ...createLightTheme(ai), 
 };

 const darkTheme = {
  ...createDarkTheme(ai)
 }

 darkTheme.colorBrandForeground1 = ai[110]; // use brand[110] instead of brand[100]
 darkTheme.colorBrandForeground2 = ai[120]; // use brand[120] instead of brand[110]

const root = createRoot(document.getElementById('root'));

 

 
root.render(
    <Provider store={store}>
  <FluentProvider theme={lightTheme} >
    <App />
  </FluentProvider>
    </Provider>
);