import React, { useState } from "react";
import styled from "styled-components";
import { TextField, Button, Grid, Card, CardContent, Typography } from '@mui/material';
import InputMask from 'react-input-mask';

const ItemsField = styled(TextField)`
&&{
    margin-bottom: 2rem;
}

`

const CompanyForm = ({handleCreateCompany}) => {

    const [data, setData] = React.useState({
        name: '',
        status: true,
        phone: '',
        platform: 'AI',
        servicesOffered: '',
        type: '',
        web: '',
        rfc: ''

    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    return (

        <Card>
            
            <CardContent >
                <Typography variant="h4" fontSize={'3rem'} align="center" padding={"2rem"} >
                    Company 
                </Typography>
                <form noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ItemsField
                                name="name"
                                label="Company Name"
                                value={data.name}
                                onChange={handleInputChange}
                                fullWidth
                            />
                               <ItemsField
                                name="rfc"
                                label="RFC"
                                value={data.rfc}
                                onChange={handleInputChange}
                                fullWidth
                            />

                            <InputMask
                                mask="(999) 999-9999"
                                value={data.phone}
                                onChange={handleInputChange}
                                >
                                    {() => <ItemsField name="phone" label="Phone" fullWidth />}
                            </InputMask>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                         
                        <ItemsField
                                name="servicesOffered"
                                label="Services Offered"
                                value={data.servicesOffered}
                                onChange={handleInputChange}
                                fullWidth
                            />

                            <ItemsField
                                type="url"
                                name="web"
                                label="Web"
                                value={data.web}
                                onChange={handleInputChange}
                                fullWidth
                            /> 
                        </Grid>
                    </Grid>
                    <Button 
                    variant="contained" 
                    color="primary"
                    onClick={() => handleCreateCompany(data)}
                     >
                        Submit
                    </Button>
                </form>
            </CardContent>
        </Card>
    );

};

export default CompanyForm;