import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Input,
  Label,
  makeStyles,
  Card,
  CardFooter,
  CardHeader,
  Text,
  Body1,
  Title1,
  Title3,
  Caption1,
  CardPreview,
  Caption2,
} from "@fluentui/react-components";
import { AddCircleRegular, FolderAddRegular,SaveRegular,DismissCircleRegular } from "@fluentui/react-icons"
import { Box } from "@mui/material";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
});

export const FolderForm = ({ close ,create}) => {
  const styles = useStyles();
  const [folder, setFolder] = React.useState('')

  const handleSubmit = (ev) => {
    ev.preventDefault();
    alert("form submitted!");
  };

  return (
    <Box>

      <Card>
        <CardHeader
          header={<Body1>

            <Text>Nuevo folder</Text>


          </Body1>}
          description={<Caption2>*Escriba el nombre del nuevo folder</Caption2>}
        />
        <CardPreview>
          <form style={{ display: 'flex', paddingLeft: '1rem' }}>
            <Input
              contentBefore={<FolderAddRegular />}
              size="medium"
              appearance="underline"
              value={folder}
              onChange={(e) => setFolder(e.target.value)}
              placeholder="Nombre del folder"
              required
            />
          </form>


        </CardPreview>
        <CardFooter >
          <Button icon={<DismissCircleRegular/>} onClick={close} >Cancelar</Button>
          <Button onClick={()=>{create(folder)}} icon={<SaveRegular/>} appearance="primary" size="" >Crear</Button>
        </CardFooter>
      </Card>

    </Box>

  );
};