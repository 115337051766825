import React, { useEffect, useState } from 'react';
import { Button, Image, InfoLabel, Input, Label, Link, MessageBar, Text, Title2, makeStyles, shorthands, tokens } from '@fluentui/react-components';

import { Box, Fade } from '@mui/material';
import { ShieldLock20Filled } from '@fluentui/react-icons'; // Asegúrate de que esta importación sea correcta y esté disponible
import logo from '../../assets/3f6dfae9-072c-4d7e-9892-1ac8280d684a_thumb.jpg';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.padding('2rem'),
        '@media (max-width: 768px)': {
            ...shorthands.padding('1rem'),
        },
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '300px',
        height: 'auto',
        width: '90vw',
        maxWidth: '500px',
        ...shorthands.gap('0.8rem'),
        ...shorthands.padding('1.5rem'),
        background: tokens.colorNeutralBackground1,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 768px)': {
            width: '100%',
            ...shorthands.padding('1rem'),
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '300px',
        height: 'auto',
        width: '90vw',
        maxWidth: '500px',
        ...shorthands.gap('0.7rem'),
        ...shorthands.padding('1.5rem'),
        background: tokens.colorNeutralBackground1,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 768px)': {
            width: '100%',
            ...shorthands.padding('1rem'),
        },
    },
    errorBar: {
        width: '100%',
    },
    button: {
        marginTop: '1rem',
        display: 'flex',
        ...shorthands.padding('0.5rem'),
    },
    microsoftButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...shorthands.padding('0.5rem'),
    },
    icon: {
        marginRight: '0.5rem',
    },
});

export const Login = ({ handleMicrosoft,redirectToRoot, handleLogin, error }) => {
    const styles = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [next, setNext] = useState(false);
 

    useEffect(() => {
        try {
            const emailInput1 = document.getElementsByClassName('flt1dlf')[0];
            emailInput1.style.padding = '0px';
        } catch (error) {
            // Manejo de errores
        }
    });

    const handleContinue = () => {
        const emailInput = document.getElementById('email');
        emailInput.setCustomValidity('');
        if (emailInput.checkValidity()) {
            setNext(true);
        } else {
            emailInput.setCustomValidity('Por favor, ingresa un correo electrónico válido.');
            emailInput.reportValidity();
        }
    };

    const handleBack = () => {
        setNext(false);
    };

    return (
        <div className={styles.container}>
            {error ? (
                <MessageBar className={styles.errorBar} intent='error'>
                    Login error
                </MessageBar>
            ) : null}
            <Box paddingTop={{lg:'18vh',sm:'1vh',md:'20vh',xl:'18vh'}}></Box>
            <Box className={styles.form} onSubmit={async (evt) => { evt.preventDefault();  }}>
                <Box onClick={redirectToRoot}  display={'flex'} justifyContent={'flex-start'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                    <Image  src={logo} alt='AXELERATUM' width={45} height={40} style={{ marginRight: '-0.5rem', marginLeft: '-0.75rem' }} />
                    <Title2>
                        XELERATUM <Text weight='bold' size={700} italic>SAGA</Text>
                    </Title2>
                </Box>
                    
                    <br/>
                {next ? (
                    <Fade in={next}>
                    <Input
                        
                        size='large'
                        appearance='underline'
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Ingresa tu contraseña"
                        style={{transition:'opacity 500ms ease-in'}}
                        required
                    />
                    </Fade>
                ) : (
                    <Input
                        appearance='underline'
                        pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
                        title="Por favor, ingresa un correo electrónico válido."
                        size='large'
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Ingresa tu correo electrónico"
                        required
                    />
                )}

            


                {next ? (
                    <Box>
                        <Fade in={next}>
                            <Link style={{opacity: 1,transition:'opasity 200ms ease-in'}}>Olvidaste el password?</Link>
                        </Fade>
                    </Box>
                ) : (
                    <Box>
                        <InfoLabel>Crear una </InfoLabel>
                        <Link appearance='default'>cuenta</Link>
                    </Box>
                )}
                <Box width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                    {next &&
                    <>
                    <Fade in={true} >
                            <Button  onClick={handleBack} size='medium' className={styles.button} style={{  marginRight: '0.5rem' , transition:'opacity 200ms ease-in'}} appearance="outline">
                                Regresar
                            </Button>
                    </Fade>
                    <Fade in={true}> 
                            <Button size='medium' className={styles.button}  onClick={()=>{handleLogin(email, password)}} appearance="primary"  style={{   transition:'opacity 200ms ease-in'}} >
                                Sign In
                            </Button>
 
                    </Fade>
                    </>
}
                    {!next &&
                    <Fade in={!next}  >
                        <Button onClick={handleContinue} size='medium' className={styles.button} style={{transition:'opacity 500ms ease-in' }} appearance="primary">
                            Continuar
                        </Button>
 
                    </Fade>
}
               </Box>
            </Box>
            <Box paddingTop={'1vh'}></Box>
            <Box className={styles.subContainer}>
                <Button className={styles.microsoftButton} onClick={handleMicrosoft} appearance="secondary">
                    <ShieldLock20Filled className={styles.icon} />
                    <Text size={300} weight='semibold'>Microsoft</Text>
                </Button>
            </Box>
        </div>
    );
};