import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { replaceMenu } from "../../redux/drawerSlice";
import { getNavBarParamGeneric } from "../../utils/BrowserUtils";
import chatServiceIa from "../../services/chat_service_ai";
import { ChatList } from "./ChatsList";
import { HistoryChats } from "./HistoryChats";
import { TypingBar } from "./TypingBar";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { EventSourcePolyfill } from 'event-source-polyfill';
import { getBase64FromDB, saveBase64ToDB } from "../Utils/IndexDB"
import RenderPdf from "../Utils/PDFViewer";
import documentService from "../../services/document_service";



const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  sidebar: {
    display: 'flex',
  },
  chatArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  messagesArea: {
    display: 'flex',
    flexDirection: 'column'
  },
  messageList: {
    flexGrow: 1,
    overflowY: 'auto',
    ...shorthands.padding('80px', '0'),
  },
});

const messageTemplate = {
  id: 1,
  primary: "",
  secondary: "",
  metadata: [],
  image: null,
  subdata: null,
  isSender: true
};

export const ChatContainer = ({ documentHash = null }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const eventSourceRef = useRef(null);

  const [chatsHistory, setChatsHistory] = useState([]);
  const [messages, setMessages] = useState({ history: [], img: null });
  const [formattedMessages, setFormattedMessages] = useState({ history: [] });
  const [answering, setAnswering] = useState(false);
  const [pdfDocumet, setPdfDocument] = useState(null);
  const [messageMetadata ,setMessajeMetadata] = useState([])

  const [pdfPage, setPdfPage] = useState(1);

  useEffect(() => {
    dispatch(replaceMenu({ component: 'HomeTemplate', props: { selected: 'chat' } }));
    loadChats();
    newChat();
  }, [dispatch, documentHash]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [formattedMessages]);

  const loadChats = async () => {
    const folderId = getNavBarParamGeneric('folderid');
    if (folderId) {
      const res = await chatServiceIa.getChats(folderId);
      setChatsHistory(res.reverse());
    }

    if (documentHash != null) {

      const res = await chatServiceIa.getChatsByDocumentHash(documentHash);
      setChatsHistory(res.reverse())

    }
  };

  const adapterMessages = (messageHistory = []) => {
    const data = messageHistory.filter(n => n.ask).flatMap((message) => {
      const result = [];
      if (message.ask) {
        result.push({
          ...messageTemplate,
          primary: 'YOU',
          secondary: message.ask,
          isSender: true
        });
      }
      if (message.answer) {
        result.push({
          ...messageTemplate,
          primary: 'BOT',
          secondary: message.answer,
          metadata: message.metadata,
          subdata: message.subdata,
          image: message.img,
          isSender: false
        });
      }
      return result;
    });

    setFormattedMessages({ history: data });
  };

  const loadMessages = async (chatId) => {
    const messages = await chatServiceIa.loadChat(chatId);
    setMessages(messages);
    adapterMessages(messages.history);
  };

  const sendMessage = (message) => {
    setAnswering(true);


    setMessages(prevMessages => {
      const updatedHistory = [...prevMessages.history, { ask: message, answer: "-1" }];
      adapterMessages(updatedHistory);
      return { ...prevMessages, history: updatedHistory };
    });

    let msj = { ...messages };
    msj.history = [];
    msj.question = null

    const asking = { ...msj, ask: message };

    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const authorizationToken = localStorage.getItem("token");
    const chatString = encodeURIComponent(JSON.stringify(asking));

    console.log(asking)

    eventSourceRef.current = new EventSourcePolyfill(`${process.env.REACT_APP_API_AI}/api/v1/ai/chat/stream?chat=${chatString}`, {
      headers: {
        'Authorization': 'Bearer ' + authorizationToken
      }
    });

    eventSourceRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setMessages(prevMessages => {
        const updatedHistory = [...prevMessages.history];
        const lastMessage = updatedHistory[updatedHistory.length - 1];
        lastMessage.answer = data.question.answer === "" ? "-1" : data.question.answer;
        lastMessage.metadata = data.question.metadata;
        lastMessage.img = data.question.img;
        lastMessage.subdata = data.question.subdata;
        adapterMessages(updatedHistory);
        return { ...prevMessages, id: data.id, history: updatedHistory };
      });
    };

    eventSourceRef.current.onerror = (error) => {
      setAnswering(false);
      if (msj.id === undefined) {
        loadChats();
      }
      console.error("Error en la conexión EventSource:", error);
      eventSourceRef.current.close();
    };
  };

  const newChat = () => {
    const folderId = getNavBarParamGeneric('folderid');
    const data = { folderId, hash: documentHash, history: [] };
    setMessages(data);
    adapterMessages(data.history);
  };

  const handleSeletedDocument = async (data,metadata) => {

    console.log(metadata)
    return null

    // if (data.id === null || data.id === undefined) {

    //   return null;

    // }

    // const document = await getBase64FromDB(data.id)
    // if (document == null) {
    //   const response = await documentService.downloadBase64Document(data.id)
    //   saveBase64ToDB(data.id, response.file)
    //   setPdfDocument(document.file)
    //   setPdfPage(data.page)
    // } else {

    //   setPdfDocument(document)
    //   console.log(document)

    //   setPdfPage(data.page)

    // }



  }

  return (
    <Box className={styles.container}>

      <Box className={styles.sidebar}>
        <ChatList
          historyChats={chatsHistory}
          loadMessages={loadMessages}
          newChat={newChat}
        />
      </Box>
      <Box className={styles.chatArea}>
        <Box className={styles.messagesArea} p={2} sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: '80%', xl: '60%' } }}>

          <Box ref={listRef} className={styles.messageList}>
            <HistoryChats messages={formattedMessages.history} selectedDocument={handleSeletedDocument} />
          </Box>
          <Box>
            <TypingBar isAnswering={answering} sendMessage={sendMessage} />
            <br />
          </Box>
        </Box>
      </Box>
      {pdfDocumet && pdfPage && (
        <Box
          
          width="100%"
          height="100%"
         
          
          display="flex"
          justifyContent="center"
          alignItems="center"
           
        >
          <Box display="flex" height={"98%"}  width={"98%"}    >
            <RenderPdf base64={pdfDocumet} pageNumber={pdfPage}  />
          </Box>
        </Box>
      )}
    </Box>
  );
};