import {
  webLightTheme,
  Button,
  Title3,
  Body1,
  Text,
  tokens
} from "@fluentui/react-components";
import { BrowserRouter, Routes, Redirect, Route, Navigate } from 'react-router-dom';
import { Box, Container } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Header } from "./components/header/Header";
import { Sidebar } from "./components/sidebar/Sidebar";
import './App.css'
import React, { useEffect, useState } from "react";

import { LoginContainer } from "./components/Login/LoginContainer";
import { CompanyContainer } from "./components/Company/CompanyContainer";
import { VaultContainer } from "./components/Vault/VaultContainer";
import { TokenExpirationHandler } from "./components/Login/TokenExpirationHandler";

import { useSelector } from "react-redux";
import { Logout } from "./components/Login/Logout";
import { HomeContainer } from "./components/Home/HomeContainer";
import { ChatContainer } from "./components/Chat/ChatContainer";
import { ProfileContainer } from "./components/Profile/ProfileContainer";
import { Landing } from "./components/Landing/Landing";
import { SheetContainer } from "./components/Sheet/SheetContainer";
import { FormContainer } from "./components/SmartDocuments/FormContainer";
import { DatabasesContainer } from "./components/Databases/DatabasesContainer";
import { pdfjs } from 'react-pdf';
 




export default function App() {


  
  const [renderHeader, setRenderHeader] = useState(false)
  const decodeToken = useSelector((data) => data.auth.token)

  useEffect(() => {

    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
   'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

    const location = window.location.pathname;

    if (decodeToken || location === '/') {
        setRenderHeader(true)
    }



  }, [decodeToken])

  return (
    <React.Fragment>
      <TokenExpirationHandler />

      {renderHeader && <Header />}
      <Sidebar />
    <Box minHeight={'100vh'}   sx={{ background: tokens.colorNeutralBackground1Hover }}   >
        <BrowserRouter>
          <Routes>
            <Route path="/profile" element={<ProfileContainer />} />
            <Route path="/chat" element={<ChatContainer />} />
            <Route path="/vault" element={<VaultContainer />} />
            <Route path="/company" element={<CompanyContainer />} />
            <Route path="/login" element={<LoginContainer />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/home" element={<HomeContainer />} />
            <Route path="/sheets" element={<SheetContainer />} />
            <Route path="/forms" element={<FormContainer />} />
            <Route path="/databases" element={<DatabasesContainer />} />
            <Route path="/" element={<Landing />} />

          </Routes>
        </BrowserRouter>
      </Box>
    </React.Fragment>


  );
}