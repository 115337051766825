import RestApi from "./restApi";


class AIVectorizeDoc {
  constructor() {
    this.api = new RestApi();
  }

  async vectorize_pdf(docData) {
    try {
      const response = await this.api.post("ai/document/load/pdf", docData);
      return response;
    } catch (error) {
      throw error;
    }
  }


    async csv_to_dataframe(hash) {
    try {
      const response = await this.api.get(`ai/csv/process?hash=${hash}` );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async excel_to_dataframe(hash) {
    try {
      const response = await this.api.get(`ai/excel/process?hash=${hash}` );
      return response;
    } catch (error) {
      throw error;
    }
  }

}

const aiVectorizeDoc = new AIVectorizeDoc();

export default aiVectorizeDoc;