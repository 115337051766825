import React from "react";
import { Avatar, Body1, Body1Strong, Button, Card, CardFooter, CardHeader, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, SearchBox, TableCellLayout, Text, Title3, createTableColumn, makeStyles } from "@fluentui/react-components"

import {
    FolderRegular,
    EditRegular,
    OpenRegular,
    DocumentRegular,
    PeopleRegular,
    DocumentPdfRegular,
    VideoRegular,
    AddCircle16Regular,
} from "@fluentui/react-icons";
import { Box } from "@mui/material";
import Paginator from "../Utils/Paginator";
import { useState } from "react";
import { HorizontalMenu } from "./HorizontalMenu";

const useStyle = makeStyles(

)


export const TextWithEllipsis = ({ text, height }) => (
    <Text
        style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: '0.3rem',
            cursor: 'default',
            width: height ? height : '8rem' // Cambia el ancho según sea necesario
        }}
    >
        {text}
    </Text>
);




const columns = [
    createTableColumn({
        columnId: "file",
        compare: (a, b) => {
            return a.file.label.localeCompare(b.file.label);
        },
        renderHeaderCell: () => {
            return "File";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout media={item.file.icon}>
                    <TextWithEllipsis text={item.file.label} />
                </TableCellLayout>
            );
        },
    }),
    createTableColumn({
        columnId: "author",
        compare: (a, b) => {
            return a.author.label.localeCompare(b.author.label);
        },
        renderHeaderCell: () => {
            return "Author";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout
                    media={
                        <Avatar
                            aria-label={item.author.label}
                            name={item.author.label}
                            badge={{ status: item.author.status }}
                        />
                    }
                >
                    <TextWithEllipsis text={item.author.label} />
                </TableCellLayout>
            );
        },
    }),
    createTableColumn({
        columnId: "lastUpdated",
        compare: (a, b) => {
            return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
        },
        renderHeaderCell: () => {
            return "Last updated";
        },

        renderCell: (item) => {
            return <TextWithEllipsis text={item.lastUpdated.label} />
        },
    })
];



export const Vault = ({ allowNavigationHeader , folders = [], currentPage, totalItems, itemsPerPage, handlePageChange,createFolder,openFolder,currentFolder }) => {

    const [selectedItem,setSelectedItem] = useState(null);

 
      const handleSelectionChange = (e, d) => {
         
             
        const selectedItemsArray = Array.from(d.selectedItems);
        const item = folders[selectedItemsArray[0]];
      
        //localStorage.setItem("selectedFolder",item.id);

        setSelectedItem(item)

        
       

         
         
        
    };
   

     

    const style = useStyle();


    return (
        <React.Fragment>

            

            <HorizontalMenu allowNavigation={allowNavigationHeader} item={selectedItem} openFolder={openFolder} createFolder={createFolder} currentFolder={currentFolder} />

            <br />


            <Card >
                <CardHeader




                />


                <DataGrid



                    selectionMode="single"
                    items={folders}
                    columns={columns}
                    focusMode="composite"
                    
                    onSelectionChange={ handleSelectionChange}




                >
                    <DataGridHeader>
                        <DataGridRow
                            selectionCell={{
                                checkboxIndicator: { "aria-label": "Select all rows" },
                            }}
                        >
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody style={{ height: '20vh', overflow: 'auto' }}>
                        {({ item, rowId }) => (
                            <DataGridRow
                                key={rowId}
                                selectionCell={{
                                    checkboxIndicator: { "aria-label": "Select row" },
                                }}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
                <CardFooter>

                    <Paginator
                        sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
                        totalItems={totalItems}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />

                </CardFooter>
            </Card>

            <Box sx={{ paddingBottom: '5.7rem' }} />




        </React.Fragment>
    )

}