import React, { useEffect } from "react"
export const Landing = () => {

    useEffect(() => {

        window.location.href = '/company'

    })
    return null;

}