import { Box, Container, TextField } from "@mui/material";
import React, { useState } from "react";
import InputMask from 'react-input-mask';
import CompanyForm from "./CompanyForm";
import styled from "styled-components";
import { ArrowReplyRegular, ShareRegular, PaymentRegular,ArrowEnterLeftRegular, SettingsRegular, AddCircleRegular, SaveRegular, DismissCircleRegular } from "@fluentui/react-icons";

import { Dropdown, Body1, Button, Caption1, Card, CardFooter, CardHeader, Text, Title2, Title3, makeStyles, tokens, shorthands, Input, Divider, Combobox, useId, Option } from "@fluentui/react-components";


const useStyles = makeStyles({
    card: {
        marginTop: '1vh',
        width: "720px",
        maxWidth: "100%",
       
    },
    card2: {
        marginTop: '2vh',
        width: "600px",
        maxWidth: "100%",
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '300px',
        height: 'auto',
        width: '100%',
        //maxWidth: '500px',
        ...shorthands.gap('0.7rem'),
        ...shorthands.padding('1.5rem'),
        '@media (max-width: 768px)': {
            width: '100%',
            ...shorthands.padding('1rem'),
        }
    }
});

const EmpresaForm = ({ handleCancel, handleCreate }) => {
    const style = useStyles();
    const comboId = useId("combo-default")
    const options = ["Free", "Standar", "Premium", "Enterprise"];
    const ItemsField = styled(TextField)`
       
        `
    const [data, setData] = React.useState({
        name: '',
        status: true,
        phone: '',
        platform: 'AI',
        servicesOffered: '',
        type: '',
        web: '',
        rfc: ''

    });
    
    return (
        <React.Fragment>

            <Card className={style.card2}>



                <CardHeader

                    header={

                        <form className={style.form}>
                            <Title3>Crear Empresa</Title3>

                            <Input placeholder="Nombre" required />
                            <Input placeholder="Rfc" required />
                            <Input placeholder="Servicios" required />
                            <InputMask
                                mask="(999) 999-9999"


                            >
                                {() => <ItemsField name="phone" label="Telefono" />}
                            </InputMask>

                            <Input placeholder="Sito web" />
                            <Divider>Planes</Divider>
                            <label id={comboId}>Selecciona un plan</label>
                            <Dropdown
                                required
                                aria-labelledby={comboId}
                            >
                                {options.map((option) => (
                                    <Option key={option} >
                                        {option}
                                    </Option>
                                ))}
                            </Dropdown>
                            <Box>
                                <Button style={{ marginRight: '0.3rem' }} onClick={handleCancel} icon={<DismissCircleRegular fontSize={20} />}>Cancelar</Button>

                                <Button appearance="primary" icon={<SaveRegular fontSize={20} />}>Guardar</Button>
                            </Box>

                        </form>

                    }
                />
                <CardFooter>
                </CardFooter>

            </Card>


        </React.Fragment>
    )
}

const Empresa = ({ company , handleSelect }) => {
    const style = useStyles()

    return (
        <React.Fragment>


            <Card className={style.card} >



                <CardHeader 
                     
                   

                    header={
                        <Body1>
                            <Text>{company.name}</Text>
                        </Body1>
                    }
                    description={<Caption1>Plan· Free - limited</Caption1>}
                />
                <CardFooter>
                    <Button onClick={()=>{handleSelect(company)}} icon={<ArrowEnterLeftRegular />} > Seleccionar</Button>
                    <Button icon={<SettingsRegular fontSize={16} />}>Editar</Button>
                    <Button icon={<PaymentRegular fontSize={16} />}>Mejorar</Button>
                </CardFooter>

            </Card>

        </React.Fragment>
    )
}

export const Company = ({ companies,handleSelect, handleCreate }) => {

    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)

    const createNew = () => {
        setCreate(true)

    }

    const handleCancelCreate = () => {

        setCreate(false)

    }

    return (
        <Container maxWidth={'xl'} >
            <Box display={'flex'} paddingTop={'5.7rem'} paddingBottom={'5.7rem'} flexDirection={'column'} width={'100%'}  >

                <Title2>Empresas</Title2>

                {

                    !create &&

                    <Button
                        style={{ width: '80px', marginTop: '2vh' }}
                        onClick={createNew}
                        icon={<AddCircleRegular fontSize={16} />} >Nueva</Button>
                }

                {
                    !create &&
                    companies.companies &&
                    companies.companies.map((data,i) => {

                        return   <Box key={i} > <Empresa handleSelect={handleSelect}   company={data} /> </Box>  

                    })
                }

                {
                    create &&
                    <EmpresaForm handleCancel={handleCancelCreate} />
                }

                {/* <CompanyForm handleCreateCompany={handleCreate}  /> */}

            </Box>

        </Container>
    )

}