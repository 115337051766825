import {
  FluentProvider,
  webLightTheme,
  Button,
  Title3,
  Text,
  tokens
} from "@fluentui/react-components";

import { ToggleMultipleRegular } from '@fluentui/react-icons';
import { Box, AppBar, Toolbar, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleLeftMenu } from "../../redux/drawerSlice";
import { useEffect, useState } from "react";
import { selectCompany } from "../../redux/companySlice";

export const Header = () => {
  const companyId = useSelector((state) => state.company.company.id);
  const dispatch = useDispatch();
  const decodedToken = useSelector((data) => data.auth.token);
  const [isLogged, setIsLogged] = useState(true);

  useEffect(() => {
    if (decodedToken != null) {
      dispatch(selectCompany());
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }, [decodedToken, companyId]);

  const redirectToLogin = async () => {
    window.location.href = '/login';
  };

  const redirectToHome = () => {
    window.location.href = '/home';
  };

  const handleToggleMenu = () => {
    dispatch(toggleLeftMenu());
  };

  return (
    <>
      {companyId && (
        <AppBar position="fixed" sx={{ backgroundColor: tokens.colorBrandBackground, color: 'white' }}>
          <Toolbar variant="dense">
            <IconButton
              sx={{ visibility: isLogged ? 'visible' : 'hidden' }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleToggleMenu}
            >
              <ToggleMultipleRegular />
            </IconButton>

            <Box style={{ flexGrow: 1 }}>
              <Title3 onClick={redirectToHome} style={{ cursor: 'pointer' }}>
                AXELERATUM <Text size={600} italic>SAGA</Text>
              </Title3>
            </Box>

            {!isLogged ? <Button onClick={redirectToLogin} color="inherit">Login</Button> : <></>}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};