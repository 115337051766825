
export const extractNavBarParams = () =>{

    const hash = window.location.hash.substring(1); // Eliminar el '#' inicial
    const paramsArray = hash.split('&');
    return paramsArray;

}

export const getNavBarParam = (param) => {

    const paramsArray = extractNavBarParams()
    const _param_ = paramsArray.find(_param => _param.startsWith(`${param}=`));
    const result =  _param_ !=null ?  _param_.split('=')[1] : null


    return result;


}

export const getNavBarParamGeneric = (param) =>{
    const url = new URL(window.location.href)

    const result = url.searchParams.get(param);
    return result;

}