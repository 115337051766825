import {
    Card, CardHeader, Overflow, OverflowItem, SearchBox, Toolbar, ToolbarButton, ToolbarDivider, useIsOverflowGroupVisible, useOverflowMenu,
    useIsOverflowItemVisible,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    Button,
    MenuItem,
    MenuDivider,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbDivider,
    BreadcrumbButton,
} from "@fluentui/react-components";
import {
    OpenRegular, BotSparkle20Regular, FolderRegular, MoreHorizontal20Filled, CalendarMonthFilled,
    CalendarMonthRegular,
    bundleIcon
} from "@fluentui/react-icons";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectDocument, toggleChat } from "../../redux/chatSlice";
const ToolbarOverflowMenuItem = (props) => {
    const { openFolder, item, id, ...rest } = props;
    const isVisible = useIsOverflowItemVisible(id);

    if (isVisible) {
        return null;
    }

    if (id.includes("open")) {
        return (
            <MenuItem onClick={() => { openFolder(item) }} icon={<OpenRegular />}>Open</MenuItem>
        );
    }

    if (id.includes("new")) {
        return (
            <MenuItem icon={<FolderRegular />}>New</MenuItem>
        );
    }

    if (id.includes("chat")) {
        return (
            <MenuItem onClick={() => { window.location.href = `/chat?folderid=${item.id}` }} icon={<BotSparkle20Regular />}>Chat</MenuItem>
        );
    }

    return <MenuItem>Item {id}</MenuItem>;
};

const ToolbarMenuOverflowDivider = (props) => {
    const isGroupVisible = useIsOverflowGroupVisible(props.id);

    if (isGroupVisible === "visible") {
        return null;
    }

    return <MenuDivider />;
};

const ToolbarOverflowDivider = ({ groupId }) => {
    const groupVisibleState = useIsOverflowGroupVisible(groupId);

    if (groupVisibleState !== "hidden") {
        return <ToolbarDivider />;
    }

    return null;
};

const ToolbarOverflowButton = ({
    onClick,
    overflowId,
    overflowGroupId,
    ...props
}) => {
    return (
        <OverflowItem id={overflowId} groupId={overflowGroupId}>
            <ToolbarButton onClick={onClick}  {...props} />
        </OverflowItem>
    );
};

const OverflowMenu = ({ item, itemIds, openFolder }) => {
    const { ref, isOverflowing } = useOverflowMenu();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {itemIds.map((group, groupIndx) => {
                        const isLast = groupIndx === itemIds.length - 1;
                        return (
                            <React.Fragment key={group.join()}>
                                {group.map((i) => (
                                    <ToolbarOverflowMenuItem key={i} id={i} openFolder={openFolder} item={item} />
                                ))}
                                {!isLast && (
                                    <ToolbarMenuOverflowDivider id={`${groupIndx + 1}`} />
                                )}
                            </React.Fragment>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};







const CalendarMonth = bundleIcon(CalendarMonthFilled, CalendarMonthRegular);
const path = "/vault";

export const Default = ({ folder }) => {
    return (
        <Breadcrumb aria-label="Breadcrumb default example">
            <BreadcrumbItem>
                <BreadcrumbButton href={path}>Root</BreadcrumbButton>
            </BreadcrumbItem>
            <BreadcrumbDivider />
            <BreadcrumbItem>
                <BreadcrumbButton icon={<FolderRegular />}>
                    {folder.name}
                </BreadcrumbButton>
            </BreadcrumbItem>
        </Breadcrumb>
    );
};



export const HorizontalMenu = ({ allowNavigation, item = null, createFolder, openFolder, currentFolder, selectedDocument = null }) => {


    const dispatch = useDispatch();

    const handleSelectDocument = () => {

        const data = { id: item.id, hash: item.hash }
        console.log(data)
        dispatch(selectDocument({ document: data }))
        dispatch(toggleChat())


    }




    return (
        <div>
            <Card style={{ width: '100%' }}>

                {currentFolder.name !== 'root' && allowNavigation && <Default folder={currentFolder} />}

                <Overflow padding={'70'} >

                    <Toolbar size="small">

                        <ToolbarOverflowButton
                            overflowId="search-1"
                            overflowGroupId="1"
                        >
                            <SearchBox size="medium" appearance="" />
                        </ToolbarOverflowButton>
                        <ToolbarOverflowButton
                            onClick={createFolder}
                            overflowId="new-1"
                            overflowGroupId="1"
                            style={{ width: '50px' }}
                            icon={<FolderRegular />}
                        >
                            New
                        </ToolbarOverflowButton>

                        {(item && (currentFolder.name === 'sheets' || currentFolder.name === 'root')) && (
                            <>
                                <ToolbarOverflowDivider groupId="1" />

                                <ToolbarOverflowButton
                                    onClick={() => allowNavigation ? window.location.href = `/chat?folderid=${item.id}` : handleSelectDocument()}
                                    overflowId="chat-1"
                                    overflowGroupId="2"
                                    icon={<BotSparkle20Regular />}
                                >
                                    Chat
                                </ToolbarOverflowButton>

                                {allowNavigation && (
                                    <ToolbarOverflowButton
                                        onClick={() => openFolder(item)}
                                        overflowId="open-1"
                                        overflowGroupId="2"
                                        icon={<OpenRegular />}
                                    >
                                        Open
                                    </ToolbarOverflowButton>
                                )}
                            </>
                        )}
                        <OverflowMenu
                            item={item}
                            openFoler={openFolder}
                            itemIds={[

                                ["new-1", "search-1", "chat-1", "open-1"],

                            ]}
                        />
                    </Toolbar>
                </Overflow>
            </Card>
        </div>
    )
};

