import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { replaceMenu } from "../../redux/drawerSlice";

export const  DatabasesContainer = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(replaceMenu({component:'HomeTemplate' ,props:{selected:'databases'}}))
    })



}