import React from "react";

export const DocumentForm = ({create, acceptedFileTypes}) => {

    const acceptAttribute = acceptedFileTypes.join(',');


    return (
        <>
            <input
                accept={acceptAttribute}
                style={{ display: 'none' }}
                id="file"
                multiple
                type="file"
                onChange={(e) => create(e)}
            />
        </>
    );
};