import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { replaceMenu } from "../../redux/drawerSlice";
import { HomeTemplate } from "../sidebar/HomeTemplate";


export const HomeContainer = () => {

    const dispatch = useDispatch();

    useEffect(()=> {

        console.log('load home template')

        dispatch(replaceMenu({component:'HomeTemplate' ,props:{selected:''}}))

    },[dispatch])

    return null;



}