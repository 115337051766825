import React from "react";
import { MenuGroup, MenuGroupHeader, MenuItem, MenuList, makeStyles, tokens } from "@fluentui/react-components";
import { bundleIcon } from "@fluentui/react-icons";
import {
    Person20Filled,
    Person20Regular,
    DocumentPdf20Filled,
    DocumentPdf20Regular,
    Building20Filled,
    Building20Regular,
    DocumentTable20Filled,
    DocumentTable20Regular,
    DatabasePlugConnected20Filled,
    DatabasePlugConnected20Regular,
    Form20Regular,
    Form20Filled
} from "@fluentui/react-icons";

// Constantes para las rutas
const ROUTES = {
    PROFILE: 'profile',
    RAG: 'vault',
    COMPANIES: 'company',
    SHEETS: 'sheets',
    FORM:'forms',
    DATABASES: 'databases'
};

// Hook personalizado para los iconos
const useIcons = () => ({
    Person: bundleIcon(Person20Filled, Person20Regular),
    Rag: bundleIcon(DocumentPdf20Filled, DocumentPdf20Regular),
    Companies: bundleIcon(Building20Filled, Building20Regular),
    Sheets: bundleIcon(DocumentTable20Filled, DocumentTable20Regular),
    Forms: bundleIcon(Form20Filled, Form20Regular),
    Databases: bundleIcon(DatabasePlugConnected20Filled, DatabasePlugConnected20Regular),
});

// Estilos
const useStyles = makeStyles({
    content: {
        padding: "0.876rem",
        background: tokens.colorNeutralBackground1Hover
    },
});

// Hook personalizado para la navegación
const useNavigation = () => {
    return (route) => {
        window.location.href = `/${route}`;
    };
};

export const HomeTemplate = ({ selected = null }) => {
    const styles = useStyles();
    const icons = useIcons();
    const handleNavigate = useNavigation();

    const menuItems = [
        { route: ROUTES.PROFILE, label: "Profile", icon: icons.Person },
        { route: ROUTES.COMPANIES, label: "Companies", icon: icons.Companies },
    ];

    const menuItemsServices = [
        { route: ROUTES.RAG, label: "Documents", icon: icons.Rag },
        { route: ROUTES.SHEETS, label: "Sheets", icon: icons.Sheets },
        { route: ROUTES.FORM, label: "Forms", icon: icons.Forms },
        { route: ROUTES.DATABASES, label: "Databases", icon: icons.Databases },
    ];

    const renderMenuItem = ({ route, label, icon: Icon }) => (

        <MenuItem
            key={route}
            onClick={() => handleNavigate(route)}
            style={{
                background: selected === route ? tokens.colorNeutralBackground1Pressed : undefined
            }}
            className={styles.content}
            icon={
                <Icon
                    filled={selected === route}
                    color={selected === route ? tokens.colorBrandStroke1 : undefined}
                />
            }
            aria-label={label}
        >
            {label}
        </MenuItem>

    );

    return (


        <MenuList>
            <MenuGroup>
                <MenuGroupHeader>General</MenuGroupHeader>

                {menuItems.map(renderMenuItem)}

            </MenuGroup>
            <MenuGroup>
                <MenuGroupHeader>Servicios</MenuGroupHeader>

                {menuItemsServices.map(renderMenuItem)}

            </MenuGroup>
        </MenuList>

    );
};