import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../redux/authSlice";

export const clearLocalStorage = async () => {
    localStorage.clear();
}

export const redirectToLoginPage = () => {
    const currentPath = window.location.pathname;
    if (currentPath !== '/login' && currentPath !== '/') {
        window.location.href = '/login';
    }
}

export const TokenExpirationHandler = () => {
    const authToken = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const [intervalTime,setIntervalTime] = useState(500)
    const [timeInSeconds ,setTimeInsecons] = useState(0);



    const calcIntervalTime = (timeBeforeExpire) => {
        if (timeBeforeExpire < 2) {
            setIntervalTime(1000);
            setTimeInsecons(timeBeforeExpire)
        } else if (timeBeforeExpire < 15) {
            setIntervalTime(30000);
        } else if (timeBeforeExpire < 20) {
            setIntervalTime(50000);
        } else if (timeBeforeExpire < 31) {
            setIntervalTime(60000);
        }
    };


    const checkTokenExpiration = async () => {
       

        const token = localStorage.getItem('decoded_token');
         
        if (!token) {
            redirectToLoginPage();
        } else {
            const { exp } = JSON.parse(token);
            const currentTime = Date.now();
            const expirationTimeInMilliseconds = exp * 1000;
            const timeUntilExpiration = expirationTimeInMilliseconds - currentTime;
            const expirationTimeInMinutes = Math.ceil(timeUntilExpiration / 60000);

            console.log(`Token expires in: ${expirationTimeInMinutes} minutes`);


            calcIntervalTime(expirationTimeInMinutes);


            if (timeUntilExpiration <= 0) {
                dispatch(setToken(null));
                await clearLocalStorage();
                redirectToLoginPage();
            } else {
                dispatch(setToken(JSON.parse(token)));
            }
        }
    }

    useEffect(() => {
        const periodicTokenCheck = async () => {
            await checkTokenExpiration();
        }

        periodicTokenCheck()

        console.log(intervalTime)

        const intervalId = setInterval(periodicTokenCheck, intervalTime);

        return () => clearInterval(intervalId);
    }, [intervalTime]);

    return null;
}