import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { replaceMenu } from "../../redux/drawerSlice";
import { HomeTemplate } from "../sidebar/HomeTemplate";

export const ProfileContainer = () => {

    const dispatch = useDispatch();

    const handleLoadMenu = () => {

        dispatch(replaceMenu({component:'HomeTemplate' ,props:{selected:'profile'}}))

    }

    useEffect(() => {
        handleLoadMenu();

    })

    return null;

}