import React, { useState } from 'react';
import { Button, Text, makeStyles , shorthands } from '@fluentui/react-components';
import { ArrowLeft16Regular, ArrowRight16Regular } from '@fluentui/react-icons';

const useStyles = makeStyles({
  paginatorContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width:'100%',
    ...shorthands.gap('10px'),
  },
});

const Paginator = ({ totalItems, itemsPerPage, currentPage, onPageChange ,sx }) => {
  const styles = useStyles();
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  //const [currentPage, setCurrentPage] = useState(1);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      const newPage = currentPage -1 ;
     // setCurrentPage(newPage);
      onPageChange(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage+1;
     // setCurrentPage(newPage);
      onPageChange(newPage);
     }
  };

  return (
    <div className={`${styles.paginatorContainer}`} style={sx} >
    
      <Button
        icon={<ArrowLeft16Regular />}
        onClick={handlePreviousPage}
        disabled={currentPage === 0}
      />
      <Text>{`Page ${currentPage+1} of ${totalPages}`}</Text>
      <Button
        icon={<ArrowRight16Regular />}
        onClick={handleNextPage}
        disabled={currentPage+1 === totalPages}
      />
    </div>
  );
};

export default Paginator;