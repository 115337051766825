import React, { useState } from "react";
import {
    Button,
    TabList,
    Tab,
    Text,
    makeStyles,
    shorthands,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerHeaderNavigation,
} from "@fluentui/react-components";
import { Add24Regular, Dismiss24Regular, ChatMultiple28Regular, ArrowLeft24Regular } from "@fluentui/react-icons";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { toggleChat  } from "../../redux/chatSlice";

const useStyles = makeStyles({
    container: {
        flexDirection: "column",
        height: "100%",
        overflowY: "auto",
        ...shorthands.gap("8px"),
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    newChatButton: {
        ...shorthands.margin("0", "0", "8px", "0"),
    },
    tabList: {
        ...shorthands.gap("4px"),
    },
    tab: {
        maxWidth: "100%",
        ...shorthands.overflow("hidden"),
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
});

export const ChatList = ({ historyChats = [], loadMessages, newChat }) => {
    const styles = useStyles();
    const [isOpen, setIsOpen] = useState(true);
    const dispatch = useDispatch();

    const handleToggle = () => setIsOpen(!isOpen);
    const handleBack = () => dispatch(toggleChat());

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: '20px',
                    top: '0rem',
                    right: '20px',
                    display: {
                        xs: isOpen ? 'none' : 'block',
                        sm: 'none'
                    },
                    zIndex: 10000
                }}
            >
                <Button
                    size="large"
                    appearance="transparent"
                    icon={<ChatMultiple28Regular color="white" />}
                    onClick={handleToggle}
                />
            </Box>
            <Box
                p={2}
                sx={{
                    paddingTop: '4rem',
                    display: {
                        sm: isOpen ? 'none' : 'block',
                        xs: 'none'
                    }
                }}
            >
                <Button
                    size="large"
                    appearance="transparent"
                    icon={<ChatMultiple28Regular />}
                    onClick={handleToggle}
                />
            </Box>
            <Box className={styles.container} style={{ display: isOpen ? 'flex' : 'none', width: isOpen ? '340px' : 'auto' }} >
                <Drawer
                    open={isOpen}
                    separator
                    type="inline"
                    style={{ overflowY: 'auto', height: '100vh' }}
                >
                    <DrawerHeader style={{ paddingTop: '4rem' }}>
                        <DrawerHeaderNavigation className={styles.header}>
                            <Button
                                aria-label="Back"
                                appearance="subtle"
                                icon={<ArrowLeft24Regular />}
                                onClick={handleBack}
                            />
                        </DrawerHeaderNavigation>
                        <DrawerHeaderTitle 
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    onClick={handleToggle}
                                    icon={<Dismiss24Regular />}
                                />
                            }
                        > 
                            Historial 
                        </DrawerHeaderTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        <TabList vertical className={styles.tabList}>
                            {historyChats.map((chat) => (
                                <Tab
                                    key={chat.id}
                                    onClick={() => loadMessages(chat.id)}
                                    className={styles.tab}
                                >
                                    <Text style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block' }}>
                                        {chat.ask}
                                    </Text>
                                </Tab>
                            ))}
                        </TabList>
                    </DrawerBody>
                    <DrawerFooter>
                        <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                appearance="primary"
                                icon={<Add24Regular />}
                                onClick={newChat}
                                className={styles.newChatButton}
                            >
                                Chat
                            </Button>
                        </Box>
                    </DrawerFooter>
                </Drawer>
            </Box>
        </React.Fragment>
    );
};