import React , {useEffect,useState} from "react";
import { useDispatch , useSelector } from "react-redux";
import { Company } from "./Company";
import {loadCompanies, selectCompany} from "../../redux/companySlice"
import companyService from "../../services/company_service";
import { replaceMenu } from "../../redux/drawerSlice";
import { HomeTemplate } from "../sidebar/HomeTemplate";
import { useNavigate } from "react-router-dom";
export const CompanyContainer = () => {


    const navigation = useNavigate()


    const dispatch  = useDispatch();
    const companies = useSelector((state) => state.company.companies)

    const handleCreate = async (data) => {

        const create = companyService.createCompany(data);

    }

    const handleLoadMenu = () => {

        dispatch(replaceMenu({component:'HomeTemplate' ,props:{selected:'company'}}))
    }

    const handleSelectCompany = async (data) => {

        const companyId = data.id;

        dispatch(selectCompany({companyId}))

        navigation('/vault')

        

    }

    useEffect(() => {

        handleLoadMenu();
        console.log(companies);
        dispatch(loadCompanies());

    },[])



    return (
        <React.Fragment>

            

            <Company companies={companies} handleSelect={handleSelectCompany} handleCreate={handleCreate}/>
            
        </React.Fragment>
    )

}