import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { replaceMenu } from "../../redux/drawerSlice";
import { fetchMainVault } from "../../redux/vaultSlice";
import { VaultContainer } from "../Vault/VaultContainer";
import folderService from "../../services/folder_service";
import documentService from "../../services/document_service";
import aiVectorizeDoc from "../../services/ai_vetorize_doc";
import { ChatContainer} from "../Chat/ChatContainer"



export const SheetContainer = () => {


    const [sheetFolder, setSheetFolder] = useState(null)
    const companyId = useSelector((state) => state.company.company.id);
    const isOpen = useSelector((state) => state.chat.open);
    const selectdDocument = useSelector((state) => state.chat.document)
    const [render, setRender] = useState(false);
    const [chatMode,setChatmode] = useState(false)


    const handleLoadSheetFolder = async () => {

        if (companyId) {
            const folder = !isOpen && await folderService.getSheetFolder(companyId);
            setSheetFolder(folder)
            setTimeout(() => {

                setRender(true)

            }, 300);
        }


    }

    const handleUploadSheet = async (e) => {
        const file = e.target.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
    
        const { id } = sheetFolder.respuesta;
    
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderId', id);
        formData.append('companyId', companyId);
    
        const doc = await documentService.createSheetDocument(formData);
    
        if (fileExtension === 'csv') {
            await aiVectorizeDoc.csv_to_dataframe(doc.ipfsHash);
        } else if (['xls', 'xlsx'].includes(fileExtension)) {
            await aiVectorizeDoc.excel_to_dataframe(doc.ipfsHash);
        } else {
            console.log('Formato de archivo no soportado');
            
        }
    
        setRender(false);
        handleLoadSheetFolder();
    };



     useEffect(() => {
    
    setRender(false)

    }, [isOpen])
    
 

    useEffect(() => {
    
        handleLoadSheetFolder();


    }, [companyId])





    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(replaceMenu({ component: 'HomeTemplate', props: { selected: 'sheets' } }))
    })

    return (

        <React.Fragment>

           { !isOpen &&
              <VaultContainer
                createDocument = {handleUploadSheet}
                visible={render}
                initFolder={sheetFolder && sheetFolder.respuesta}
                title="Sheets"
                fileTypes={['.csv', '.xls', '.xlsx']}
                allowNavigationHeader={false} />}


            {isOpen &&    <ChatContainer documentHash={selectdDocument.hash} /> }

        </React.Fragment>

    )

}