import axios from 'axios';

class LoginService {
    constructor() {
        this.baseUrl = '/v1/';
        this.axiosInstance = axios.create({
            baseURL: this.baseUrl,
            headers: { 'Content-Type': 'application/json' },
            timeout: 2000 // 600ms timeout
        });
    }

    async post(url, data) {
        try {
            const response = await this.axiosInstance.post(url, data);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error) {
        if (error.response) {
            throw new Error(error.response.data.message || 'Hubo un problema con la solicitud');
        } else if (error.request) {
            throw new Error('No se recibió respuesta del servidor');
        } else {
            throw new Error('Error al configurar la solicitud');
        }
    }

    async generateMicrsoftSSOLink(redirectUri) {
        try {
            return await this.post('auth/generateLoginEnpoint', { redirect_uri: redirectUri });
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema al generar el enlace SSO de Microsoft');
        }
    }

    async loginWitCode(code, code_verifer, redirectUri) {
        const data = {
            code,
            code_verifier: code_verifer,
            redirect_uri: redirectUri
        };

        try {
            return await this.post(`auth/tokenByCode`, data);
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema al iniciar sesión con el código');
        }
    }

    async login(username, password) {
        try {
            return await this.post('auth/login', { email: username, password: password });
        } catch (error) {
            throw new Error(error.message || 'Hubo un problema al iniciar sesión');
        }
    }
}

const loginService = new LoginService();

export default loginService;