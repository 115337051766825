import React, { useEffect } from "react";
import { clearLocalStorage } from "./TokenExpirationHandler";
import { useNavigate } from "react-router-dom"

export const Logout = () => {

    const navigate = useNavigate()


    useEffect(() => {

        const init = async () => {

            await clearLocalStorage()
            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);


        }

        init();



    })

    return null;


}