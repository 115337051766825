import RestApi from "./restApi";

class DocumentService {
  constructor() {
    this.restApi = new RestApi();
  }

  // Métodos para el endpoint documents
  async createDocument(documentData) {
    try {
      const response = await this.restApi.multipartPost("documents", documentData);
      return response;
    } catch (error) {
      throw new Error(error.message || 'Hubo un problema con la solicitud');
    }
  }

  async createSheetDocument(documentData) {
    try {
      const response = await this.restApi.multipartPost("documents/spreadsheet", documentData);
      return response;
    } catch (error) {
      throw new Error(error.message || 'Hubo un problema con la solicitud');
    }
  }


  async updateDocument(documentId, documentData) {
    try {
      const response = await this.restApi.patch(`documents/${documentId}`, documentData);
      return response;
    } catch (error) {
      throw new Error(error.message || 'Hubo un problema con la solicitud');
    }
  }

  async getDocument(documentId) {
    try {
      const response = await this.restApi.get(`documents/${documentId}`);
      return response;
    } catch (error) {
      throw new Error(error.message || 'Hubo un problema con la solicitud');
    }
  }

  async deleteDocument(documentId) {
    try {
      const response = await this.restApi.delete(`documents/${documentId}`);
      return response;
    } catch (error) {
      throw new Error(error.message || 'Hubo un problema con la solicitud');
    }
  }

  async downloadBase64Document(documentId) {
    try {
      const response = await this.restApi.get(`documents/download-base64/${documentId}`);
      return response;
    } catch (error) {
      throw new Error(error.message || 'Hubo un problema con la solicitud');
    }
  }

}

const documentService = new DocumentService();
export default documentService;