import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerHeaderTitle,
    Label,
    MenuItem,
    MenuList,
    Radio,
    RadioGroup,
    makeStyles,
    tokens,
    useId,
} from "@fluentui/react-components";
import {
    Person20Filled,
    Person20Regular,
    Chat20Filled,
    BotSparkle20Regular,
    BotSparkle20Filled,
    Chat20Regular,
    Settings20Filled,
    Settings20Regular,
    Vault20Filled,
    Vault20Regular,
    Building20Filled,
    Building20Regular,
    bundleIcon,
    Dismiss24Regular,
    ArrowExit20Filled,
    ArrowExit20Regular
} from "@fluentui/react-icons";
import { Box } from "@mui/material";
import { toggleLeftMenu } from "../../redux/drawerSlice";
import { HomeTemplate } from "./HomeTemplate";


const useStyles = makeStyles({
    root: {
     
        overflow: "hidden",
        display: "flex",
        height: "100vh",
       
        background: tokens.colorNeutralBackground1Hover
    },
    content: {
        padding: "0.876rem",
        background: tokens.colorNeutralBackground1Hover


    },
    field: {
        display: "flex",
        marginTop: "4px",
        marginLeft: "8px",
        flexDirection: "column",
        gridRowGap: tokens.spacingVerticalS,
    },
});

const Person = bundleIcon(Person20Filled, Person20Regular);
const Chat = bundleIcon(BotSparkle20Filled, BotSparkle20Regular);
const Settings = bundleIcon(Settings20Filled, Settings20Regular);
const Vault = bundleIcon(Vault20Filled, Vault20Regular);
const Compnaies = bundleIcon(Building20Filled, Building20Regular)
const Exit = bundleIcon(ArrowExit20Filled, ArrowExit20Regular)

const componentMap = {
    HomeTemplate: HomeTemplate,
    // Agrega aquí otros componentes si es necesario
};

export const Sidebar = (props) => {
    const dispatch = useDispatch()
    const toggle = useSelector((data) => data.drawer.isOpen)
    const actualMenu = useSelector((data) => data.drawer.menu)
    const styles = useStyles();
    const [open, setOpen] = useState(false)

    useEffect(() => {

        setOpen(toggle)
        console.log(toggle)

    }, [toggle])

    const ActualMenuComponent = actualMenu ? componentMap[actualMenu.component] : null;



    return (
        <React.Fragment>

            <Drawer
               
                size="small"
                className={styles.root}
                type="overlay"
                separator
                open={open}
            >

                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                onClick={() => { dispatch(toggleLeftMenu()) }}
                                icon={<Dismiss24Regular />}

                            />
                        }
                    >
                        Menu
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody>

                    {actualMenu && React.createElement(ActualMenuComponent, actualMenu.props)}

                </DrawerBody>

                <DrawerFooter  >
                    <Box width={'100%'} sx={{display:'flex'   ,    justifyContent:'flex-end'}}>
                    <MenuItem
                        onClick={() => { window.location.href = "/logout" }}
                        className={styles.content}
                        icon={<Exit />}>Logout</MenuItem>


                    </Box>
                </DrawerFooter>


            </Drawer>





        </React.Fragment>
    )


};